<template>
  <div class="vg_mtb_16">
    <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMtrlsNow()">
      <el-row>
        <el-col :md="7">
          <el-form-item label="物料编号：">
            <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="7">
          <el-form-item label="物料名称：">
            <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="7">
          <el-form-item label="物料类型：">
            <el-select size="small" v-model="searchForm.mtrl_type" placeholder="请选择物料类型" clearable >
              <el-option
                  v-for="item in mtrlTypeGroupCrft"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="3">
          <el-form-item>
            <el-button size="small" type="primary" icon="el-icon-search" @click="getMtrlsNow()" class="vg_ml_16">查询</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="vd_mar15">
      <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
      <el-button size="mini" type="primary" @click="confirmIn()">确认选择</el-button>
    </el-row> 
    <el-row>
      <el-col :md="24">
        <el-table ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" :row-key="getRowKey" border v-loading="loading">
          <el-table-column type="selection" width="48"  align="center" :reserve-selection="true"/>
          <el-table-column label="物料编号" prop="mtrl_no"/>
          <el-table-column label="物料名称" prop="mtrl_name" />
          <el-table-column label="类型" prop="mtrl_type"  :formatter="formatLeavType" />
          <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" />
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {get} from "@api/request";
import {mtrlAPI} from "@api/modules/mtrl"
import pubPagination from "@/components/common/pubPagination";
import options from "@/views/component/common/options";
export default {
  name: "MpinAddEditIn",
  components:{
    pubPagination
  },
  data() {
    return {
      tableData:[],
      searchForm:{
        mtrl_no:'',
        mtrl_name:'',
        mtrl_type:'',
      },
      totalPage:0,
      btn:{},
      loading: true,
      multiSelection: [],
      currentPage:1,
    }
  },
  created() {
    this.initData()
    this.mtrlTypeGroupCrft = options.mtrlUnit;
  },
  methods:{
    initData(){
      this.getMtrlsList()
    },
    // 获取物料信息
    getMtrlsList(){
      get(mtrlAPI.getMtrlsInclusive,{
        mtrl_no:this.searchForm.mtrl_no,
        mtrl_name:this.searchForm.mtrl_name,
        mtrl_type:this.searchForm.mtrl_type,
        page_no: this.currentPage,
      })
          .then(res=>{
            if(res.data.code === 0){
              this.loading = false;
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total;
              this.btn = res.data.data.btn;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time)
    },
    // 查询方法
    getMtrlsNow(){
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.getMtrlsList()
    },
    // 确认选择
    confirmIn(){
      this.$emit("confirmIn",this.multiSelection)
    },
    // 取消选择
    cancel(){
      this.clear()
    },
    // 清空
    clear(){
      this.$refs.multiTable.clearSelection();
    },
    // 指定一个key标识这一行的数据
    getRowKey (row) {
      return row.mtrl_id;
    },
    // 多选
    handleSelectionChange(val){
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.getMtrlsList()
    },
    // 物料类型
    formatLeavType(row){
      if(row.mtrl_type === 1){
        return '面料'
      } else if(row.mtrl_type === 2){
        return '非面料'
      }
    },
  }
}
</script>

<style scoped lang="scss">
.vd_mar15{
  margin: 15px 0;
}
</style>